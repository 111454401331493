@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
body, html {
  padding: 0px;
  margin: 0px; }
  body .sec404, html .sec404 {
    min-height: 100vh;
    background-color: #F4F4F4;
    display: flex;
    justify-content: center;
    align-items: center; }
    body .sec404 > div, html .sec404 > div {
      max-width: 460px;
      display: flex;
      flex-direction: column; }
    body .sec404 .caption, html .sec404 .caption {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 20px;
      line-height: 160%;
      letter-spacing: -0.03em;
      color: #000000;
      opacity: 0.86;
      margin: 35px 0 0; }
    body .sec404 p, body .sec404 li, html .sec404 p, html .sec404 li {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: -0.03em;
      color: rgba(0, 0, 0, 0.5); }
    body .sec404 .lnk-wrap, html .sec404 .lnk-wrap {
      display: flex;
      justify-content: center; }
    body .sec404 a, html .sec404 a {
      font-family: Montserrat;
      font-weight: 600;
      font-size: 13px;
      line-height: 134.77%;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #000000;
      padding: 15px 45px;
      border: 2px solid #FCD619;
      border-radius: 50px;
      text-decoration: none;
      text-align: center; }
    body .sec404 ol, html .sec404 ol {
      list-style: none; }
      body .sec404 ol li, html .sec404 ol li {
        position: relative;
        margin: 10px 0; }
      body .sec404 ol li:before, html .sec404 ol li:before {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.8);
        color: #F4F4F4;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        left: -30px; }
      body .sec404 ol li:nth-child(1):before, html .sec404 ol li:nth-child(1):before {
        content: '1'; }
      body .sec404 ol li:nth-child(2):before, html .sec404 ol li:nth-child(2):before {
        content: '2'; }
